import Waves from '../images/wave.svg';


export default function Footer() {

    const DateAndCopyright = () => {
        const date = new Date().getFullYear();

        return (
            <div id='date'>&copy; MoonSo Yoga - {date} </div>
        );
    }

    return (
        <>
            <img src={Waves} className="wave-footer" alt="waves" />
            <footer>
                <div className="links">
                    <article>
                        <h3>Pages</h3>
                        <ul>
                            <li>Accueil</li>
                            <li>Gagner</li>
                            <li>Boutique</li>
                        </ul>
                    </article>
                    <article>
                        <h3>Légales</h3>
                        <ul>
                            <li>Mentions légales</li>
                            <li>Politique de confidentialité</li>
                            <li>Conditions générales d'utilisation</li>
                        </ul>
                    </article>
                </div>
                <DateAndCopyright />
            </footer>
        </>
    );
}