import { createElement } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../images/logo-black.webp';

export default function Menu() {

    // Effet sur la navbar et sur le refreshing

    const StateNavbar = () => {
        const hamburger = document.getElementById('hamburger')
        const links = document.getElementById('links')
        hamburger.classList.toggle('resetNav')
        links.classList.toggle('hamburger-block')
    }
    

    const NavBarSwitch = () => {
        const links = document.getElementById('links')
        const hamburger = document.getElementById('hamburger')
        links.classList.toggle('hamburger-block')
        hamburger.classList.toggle('resetNav')
    }

    // Effet sur le menu hamburger en cliquant



    const setColor = (statut, arg, aim) => {
        document.getElementById(aim).classList.add(arg)

        if (statut === 0) {
            document.getElementById(aim).classList.remove(arg)
        }
    }

    const changeColor = () => {
        if (window.scrollY >= 100) {
            setColor(1, 'bg-color-black', 'amd')
            setColor(1, 'zoom90', 'image-nav')
            setColor(1, 'scrollHam', 'hamburger')

        } else {
            setColor(0, 'bg-color-black', 'amd')
            setColor(0, 'zoom90', 'image-nav')
            setColor(0, 'scrollHam', 'hamburger')
        }
    }

    window.addEventListener('scroll', changeColor);

    // Effet sur le scroll

    const OpenModal = () => {
        const modal = document.getElementById('modal')
        modal.innerHTML="";   
    }


    return (
        <header>
            <div id='modal'></div>
            <nav id='amd' className='navfromeverywhere'>
                <img
                    src={Logo}
                    className='menu-image'
                    id='image-nav'
                    height="100px"
                    width="100px"
                    alt='logo' />
                <ul id="links" className='menu-navbar hamburger-block'>
                    <li>
                        <NavLink onClick={StateNavbar} to="/">
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink onClick={StateNavbar} to="/enseignements">
                            Enseignements
                        </NavLink>
                    </li>
                    <li>
                        <NavLink onClick={StateNavbar} to="/cours">
                            Cours
                        </NavLink>
                    </li>
                    <li>
                        <NavLink onClick={StateNavbar} to="/guides">
                            Guides
                        </NavLink>
                    </li>
                    <li>
                        <NavLink onClick={StateNavbar} to="/presentation">
                            Mooneso Yoga
                        </NavLink>
                    </li>
                    <li>
                        <button onClick={OpenModal} className='button-nav'>Clients</button>
                    </li>
                </ul>
                <input onClick={NavBarSwitch} id="toggle" type="checkbox"></input>

                <label htmlFor="toggle" id='hamburger' className="hamburger">
                    <div className="top-bun"></div>
                    <div className="meat"></div>
                    <div className="bottom-bun"></div>
                </label>
            </nav>
        </header>
    );
}