import ImageYogaHead from '../images/yoga1_removed.png'
import React, { useEffect, useState, useRef } from 'react';

export default function Home() {

    useEffect(() => {
        for (const btn of document.querySelectorAll(".faq-question")) {
            btn.addEventListener("click", evt => {
                evt.target.closest(".faq-question").classList.toggle("effect-slidebox");
            });
        }
    })

    const scrollDiv = (which) => {
        const red = document.querySelectorAll(".faq-question");
        const arrow = document.querySelectorAll("#arrow");
        const text = document.querySelectorAll(".displayNull");

        text[which].classList.toggle("faq-test")
        red[which].classList.toggle("effect-slidebox")
        arrow[which].classList.toggle("arrow-clicked")

    };


    return (
        <>
            <section className="first-home">
                <img className='first-home-img' alt='yoga moonso' src={ImageYogaHead} />
                <div className='first-home-text-container'>
                    <h1>MoonSO Yoga</h1>
                    <p>Feel the lightness of your body</p>
                </div>
            </section>
            <section className='second-home'>
                <article className='second-home-1'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse faucibus interdum posuere lorem. Suspendisse sed nisi lacus sed viverra tellus in. Sem nulla pharetra diam sit amet. Elementum eu facilisis sed odio. Sem integer vitae justo eget magna fermentum iaculis eu. Sed vulputate odio ut enim blandit volutpat. Enim ut sem viverra aliquet eget sit amet. Mi bibendum neque egestas congue quisque egestas diam in.</p>
                </article>
                <article className='second-home-2'>
                    <h2>Actualités</h2>
                    <article onClick={() => scrollDiv(0)} className="faq-question">
                        <div className='divDisplay'>
                            <div className="border-arrow">
                                <div id="arrow" className="arrow right"></div>
                            </div>
                            <h3>I succeeded to install games on the PS3</h3>
                        </div>
                        <p className='displayNull'>Nullam sagittis, justo ut interdum hendrerit, nisl justo eleifend tellus, eu vestibulum tellus ante nec eros. Vivamus pharetra laoreet massa vitae rhoncus.</p>
                    </article>
                    <article onClick={() => scrollDiv(1)} className="faq-question">
                        <div className='divDisplay'>
                            <div className="border-arrow">
                                <div id="arrow" className="arrow right"></div>
                            </div>
                            <h3>I succeeded to install games on the PS3</h3>
                        </div>
                        <p className='displayNull'>Nullam sagittis, justo ut interdum hendrerit, nisl justo eleifend tellus, eu vestibulum tellus ante nec eros. Vivamus pharetra laoreet massa vitae rhoncus.</p>
                    </article>
                    <article onClick={() => scrollDiv(2)} className="faq-question">
                        <div className='divDisplay'>
                            <div className="border-arrow">
                                <div id="arrow" className="arrow right"></div>
                            </div>
                            <h3>I succeeded to install games on the PS3</h3>
                        </div>
                        <p className='displayNull'>Nullam sagittis, justo ut interdum hendrerit, nisl justo eleifend tellus, eu vestibulum tellus ante nec eros. Vivamus pharetra laoreet massa vitae rhoncus.</p>
                    </article>
                    <article onClick={() => scrollDiv(3)} className="faq-question">
                        <div className='divDisplay'>
                            <div className="border-arrow">
                                <div id="arrow" className="arrow right"></div>
                            </div>
                            <h3>I succeeded to install games on the PS3</h3>
                        </div>
                        <p className='displayNull'>Nullam sagittis, justo ut interdum hendrerit, nisl justo eleifend tellus, eu vestibulum tellus ante nec eros. Vivamus pharetra laoreet massa vitae rhoncus.</p>
                    </article>
                </article>
            </section>
        </>
    );
}
