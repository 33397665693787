import PlaceHolder from '../images/womanplaceholder.png';

export default function Enseignements() {

    return (
        <main className="first-enseignements">
            <h1 className="h1-enseignements">Nos enseignements</h1>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Vinyasa Flow</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Stretch & Flow</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Yin Yoga</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Healing bowls</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Médiation | Mindfulness</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Prāṇāyāma</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Yoga de la femme</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>

            <article className="enseignements-block">
                <img src={PlaceHolder} width="" height="200" alt="spécialité actuelle" />
                <div className='enseignements-text'>
                    <h2>Moon’s flow</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                </div>
            </article>
        </main>
    );
}