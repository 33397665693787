export default function Test() {

    return (

        <div className='filter'>
            <form className="formOfModal modal-inscription">
                <h2 className="TextAndTimes">Inscription <span id="times">&times;</span></h2>
                <p type="Nom:"><input placeholder="Ecrivez votre nom ici.."></input></p>
                <p type="Numéro de téléphone:"><input placeholder="Ecrivez votre numéro de téléphone ici.."></input></p>
                <p type="Email:"><input placeholder="Ecrivez votre email ici.."></input></p>
                <p type="Mot de passe:"><input placeholder="Ecrivez votre mot de passe ici.."></input></p>
                <p type="Repetez votre mot de passe:"><input placeholder="Reécrivez votre nom ici.."></input></p>
                <button className="Modalbutton">Inscription</button>
                <p className="text-onside">Pas encore de compte ? <span>Inscrivez vous</span></p>
            </form>
            <form className="formOfModal modal-connexion">
                <h2>connexion <span id="times">&times;</span></h2>
                <p type="Nom:"><input placeholder="Ecrivez votre nom ici.."></input></p>
                <p type="Numéro de téléphone:"><input placeholder="Ecrivez votre numéro de téléphone ici.."></input></p>
                <p type="Email:"><input placeholder="Ecrivez votre email ici.."></input></p>
                <p type="Mot de passe:"><input placeholder="Ecrivez votre mot de passe ici.."></input></p>
                <p type="Repetez votre mot de passe:"><input placeholder="Reécrivez votre nom ici.."></input></p>
                <button className="Modalbutton">Inscription</button>
            </form>
        </div>
    );
}