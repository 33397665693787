import React, { Component } from 'react';

import M from 'materialize-css'

export default class Slider extends Component {

    componentDidMount() {
        var elem = document.querySelector('.carousel');
        var instance = M.Carousel.init(elem, { duration: 200 });
    }

    render() {

        return (
            <main className="first-cours">
                <h1 className="h1-enseignements">Nos cours</h1>
                <div className="carousel">
                    <article className="carousel-item">
                        <img src="http://c5com.com/wp/wp-content/uploads/2011/05/400x400.png" />
                    </article>
                    <article className="carousel-item">
                        <img src="http://c5com.com/wp/wp-content/uploads/2011/05/400x400.png" />
                    </article>
                    <article className="carousel-item">
                        <img src="http://c5com.com/wp/wp-content/uploads/2011/05/400x400.png" />
                    </article >
                    <article className="carousel-item">
                        <img src="http://c5com.com/wp/wp-content/uploads/2011/05/400x400.png" />
                    </article>
                    <article className="carousel-item">
                        <img src="http://c5com.com/wp/wp-content/uploads/2011/05/400x400.png" />
                    </article>
                </div>
            </main>
        );
    }
}