

export default function Presentation() {

    return (
        <>
        <main className="first-presentation">
            <section className="first-of-presentation">
                <img src="https://wilcity.com/wp-content/uploads/2018/12/placeholder-500x300.png" />
                <h1>Qui suis-je</h1>
            </section>
        </main>
        </>
    )
}