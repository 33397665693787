import { Routes, Route } from 'react-router-dom';

//Components
import Menu from './components/Menu.js';
import Footer from './components/Footer.js';

//Pages
import Home from './pages/Home.js';
import Presentation from './pages/Presentation.js';
import Cours from './pages/Cours.js';
import Enseignements from './pages/Enseignements.js';
import Test from './pages/TestPage';

//CSS
import './css/index.css';
import './css/menu.css';
import './css/home.css';
import './css/presentation.css';
import './css/enseignements.css';
import './css/cours.css';
import './css/footer.css';

function App() {
  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/enseignements" element={<Enseignements />} />
        <Route path="/cours" element={<Cours />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/test" element={<Test />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
